/*enterprise-hospital-emrg-tec css start*/
.enter-hosp-emrg-tech-content ul{ display: flex; flex-wrap: wrap;  }
.enter-hosp-emrg-tech-content ul li{   flex: 0 14%;  text-align: center;  margin-right: 6%; }
.enter-hosp-emrg-tech-content ul li:last-child{ margin-right: 0px; }
.enter-hosp-emrg-tech-content ul li a{ font-family: 'rubikmedium'; color:#393939;  background: #f7f7f7;
    padding: 20px; border-radius: 20px; text-align: center; display: block; height: 100%; transition:all 1s ease;
     line-height: 13px;}
.enter-hosp-emrg-tech .container .col-md-9{ margin: 0 auto; }
.enter-hosp-emrg-tech-content ul li img{ padding-bottom: 20px; }
.enter-hosp-emrg-tech-content ul li span{ font-size: 13px; }
.enter-hosp-emrg-tech-content ul li a.more-menu-icon{ background: none; }
.enter-hosp-emrg-tech-content ul li a:hover{ background: #fff;
    box-shadow: #ececec 1px 10px 20px;
    color: #f00;
    border-radius: 20px;
}
.srch_bx {
            clear: both;
    float: left;
    border: 50px;
    border: #eeecec solid 1px;
    border-radius: 50px;
    width: 100%;
    margin-top: 55px;
    margin-bottom: 40px;
 
}

.srchbxinpt {
   float: left;
    width: 90%;
    background: 0 0;
    border: none;
    color: #494949;
    font-size: 18px;
    padding: 12px 10px 10px 45px;
    border-radius: 50px;
    letter-spacing: 4px;
}
.srchbxinpt::placeholder{ color: #494949;  }
.srchbxinpt:focus{ border: none; outline: none; }
.srchbtn {    background: url("../images/home/srch-icon.webp") no-repeat left top 2px;
    float: right;
    width: 30px;
    height: 30px;
    cursor: pointer;
    border: none;
    margin-top: 7px;
    margin-right: 40px;
    background-size: 100%;
}

/* .symptom-speak-txt ul{ display: flex; flex-wrap: wrap;  } */
.symptom-speak-txt ul li{   flex: 0 0 18%;   text-align: center;  }
.symptom-speak-txt ul li a{  font-family: 'rubikregular';
    color: #393939;
    border: #e7e7e7 solid 1px;
    padding: 10px 20px 10px;
    border-radius: 50px;
    text-align: center;
    display: block;
    height: 60px;
    transition: all 1s ease;
    line-height: 18px;
    font-size: 14px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 15px;
 
}
/* .symptom-speak-txt{ display: flex; justify-content: center; } */
/* .symptom-speak-txt ul li a:first-child{ margin-right: 0px; } */
.symptom-speak-txt ul li a.active{     background: #f30000;
    box-shadow: #fcc1c1 0px 2px 15px;
    color: #fff;
    border-radius: 50px;}
.symptom-speak-txt ul li a:hover{ background:#f30000;   box-shadow: #fcc1c1 0px 2px 15px;
    color: #fff;  border-radius: 50px; }
.symptom-speak-txt .slick-list{ height:100px; }



@media only screen and (max-width: 1680){
	.enter-hosp-emrg-tech-content ul li span {
	    font-size: 12px;
	}    

}

@media only screen and (max-width: 1440px){
.symptom-speak-txt ul li a{ font-size: 12px; padding: 0px 10px; }
}

@media only screen and (max-width: 1366px){
.symptom-speak-txt ul li a{         font-size: 11px;
    line-height: 13px;
    height: 45px; }
.enter-hosp-emrg-tech-content ul li{  margin-right: 5%; }
}

@media only screen and (max-width: 1024px){
.enter-hosp-emrg-tech-content ul li{  flex: 0 22%;  margin-right: 3%; }
.symptom-speak-txt ul li {
    flex: 0 31%;
    text-align: center;
    margin-right: 2%;
    margin-bottom: 10px;
} 
.symptom-speak-txt {
    display: unset;
}
}

@media only screen and (max-width: 1366px){
.enter-hosp-emrg-tech-content ul li{     margin-bottom: 30px; }

}

@media only screen and (max-width: 767px){
.enter-hosp-emrg-tech-content ul li a.more-menu-icon{ padding-bottom: 0px;}
.enter-hosp-emrg-tech-content ul li { 
    flex: 0 27%;
    margin-right: 3%;
    margin-left: 3%;
    margin-bottom: 10px;}
.srchbxinpt{     width: 80%;
    padding: 12px 10px 10px 15px;
    font-size: 12px; }
.enter-hosp-emrg-tech-content ul li span{     flex-wrap: wrap;
    display: flex; justify-content: center;}
.enter-hosp-emrg-tech{ padding:0px;  }
.enter-hosp-emrg-tech-content{ padding-top: 0px;	 }
.srchbtn{     margin-top: 5px; }
.symptom-speak-txt{ display: unset; }
.symptomXMb .slick-slide{ opacity: 1; margin-right: 0px;  }
.symptomXMb .slick-slide>div{ margin: 0 5px;}
.symptom-speak-txt ul li a{ padding: 0px 15px; }
.symptom-speak-txt ul li a{ border-radius: 15px; margin:0px;}
.mb-how-help{ background:#df3021; padding-top: 50px; padding-bottom: 0px;  }
.symptom-speak-txt ul li a:hover{     border-radius: 22px;	 }
.srch_bx{     height: 58px;
    line-height: 37px; 
}
.srchbxinpt{ letter-spacing: 1px;
    font-weight: 600; } 
.srchbxinpt{ color:#df3021;  }    
.srchbxinpt::placeholder{ color:#df3021; }
.srchbtn{     margin-top: 12px !important;
    margin-right: 30px; }
.symptom-speak-txt ul li a, .symptom-speak-txt ul li a.active{ box-shadow: none; border:none; } 

}
@media only screen and (max-width: 480px){
.enter-hosp-emrg-tech-content ul li {
    flex: 0 48%;
    margin-right: 1%;
    margin-left: 1%;}
.symptom-speak-txt ul li{ flex: 0 49%;
    text-align: center;
    margin-right: 1%;
    margin-bottom: 10px; }    
}


/*enterprise-hospital-emrg-tec css start*/



/*banner css start*/
.button-banner{

    border: unset;
    background: #000;
    border-radius: 50px;
    padding: 7px 14px 7px 1px;

}
.form-submit-btn{
    background: #fff;
    border-radius: 50px;
    padding: 8px 24px 8px 23px;
    color: #000;
}
.image-arrow{
    padding-left: 6px;
    width: 22px;
    height: 16px;
    margin-top: 0px;
}
.banner-left-cnt{     border: #0f0f0f solid 1px;
    border-radius: 40px;
    padding: 30px 30px 0px;
 }
.banner-left-cnt h2{ font-family: 'rubikregular'; color:#000; font-size:30px; }
.banner-left-cnt h3{  font-family: 'rubikbold';
    color: #000;
    font-size: 32px;
    margin-bottom: 25px;
 }
.banner-left-cnt h3 span{ background: #f00;
    color: #fff;
    border-radius: 50px;
    width: 45px;
    height: 45px;
    display: inline-block;
    position: relative;
    text-align: center;
    line-height: 47px; }

.input-name-field {
  width: 100%;
    border-bottom: #454545 solid 1px;
    font-size: 16px;
    height: 50px;
    margin-bottom: 30px;
    border-top: none;
    border-right: none;
    border-left: none;
    color: #747474;
}
.banner-left-cnt .error-msg{ margin-top: -27px;}
.input-name-field:focus, .input-name-field:hover {
    border-bottom: #454545 solid 1px;
    outline: unset !important; color:#747474;
}    
.input-name-field::placeholder{ color:#747474;  }
.banner-right-cnt h2{ font-family: 'rubikbold'; color:#231f20; font-size:30px;  }  
.banner-right-cnt span{ color:#f30000; font-size:25px; }  
.banner-right-cnt p{     font-size: 14px;
    color: #000000;
    line-height: 20px;  }
.banner_slider .slick-next{    background: #f30000;
    top: -36px; right: 0px;
    width: 45px;
    height: 12px;
    border-radius: 50px;
}
.banner_slider .slick-prev{    background: #abacad;
    top: -36px;
    right: -24px;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    left: inherit;
}		  
.banner_slider .slick-next:before, .banner_slider .slick-prev:before { content:"" !important; }  
.banner_slider button:before{ content:inherit !important;  }
.banner_slider .slick-dots li{ background:#abacad; border-radius: 50px; width: 12px; height: 12px;  }
.banner_slider .slick-dots li.slick-active{ background:#f30000;  width: 45px; height: 12px; }
.banner_slider .slick-dots{ top: -60px; right: 0px; text-align: right;  }
.desktop-banner-image { display:block !important;}
.mobile-banner-image{ display:none !important;}
.bnr__cntnt {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 10;
}
.vrtclcntr_bx {
    float: left;
    width: 100%;
    height: 100%;
    display: table;
    position: relative;
}
.vrtclcntr_bxinr {
    display: table-cell;
    vertical-align: middle;
}
.banner_txt {
    width: 47%;
    float: left;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.bnr_subtxt {
    float: left;
    width: 100%;
    font-size: 28px;
    color: #fff;
  
    line-height: 31px;
    padding-top: 25px;
}
.bnrbx img{ width: 100%; height: 100%; }


@media only screen and (max-width: 1800px) {

    .book-clr-btn{  padding-top: 10px; padding-bottom: 12px; }
    .navinr li a{ font-size: 14px; }
    }
    
  
@media only screen and (max-width: 1680px){
.banner-left-cnt h2{ font-size: 24px;}
.banner-left-cnt h3 { font-size: 24px;}
.banner-left-cnt h3 span{       width: 40px;
    height: 40px;  line-height: 40px; }
    .input-name-field{ height: 40px; }
    .bnrbx img{ min-height: 375px; }
}

@media only screen and (max-width: 1024px){
.banner-left-cnt h2 {
    font-size: 15px;
}
.banner-left-cnt h3 {
    font-size: 14px;
}
.banner-left-cnt h3 span {
    width: 28px;
    height: 28px;
    line-height: 32px;
}
.banner-left-cnt{ padding: 30px 20px; }
.book-clr-btn{ padding-left: 0px !important; }
.banner-right-cnt h2{ font-size: 11px;
    line-height: 10px; }
.banner-right-cnt span{ font-size: 12px; } 
.banner-right-cnt p{ font-size: 12px; }   
}

@media only screen and (max-width: 767px){
.banner_slider{  margin-top: 0px; display: none; }
.desktop-banner-image { display:none !important;}
    
.mobile-banner-image { display:block !important;}
}
/*end banner css*/

/*redefining css start*/

.air-ambilances-tp h2{ color: #231f20; font-size: 21px; font-family: 'rubikmedium'; padding-bottom: 10px;}
.air-ambilances-bt{     background: #ebebeb;
    border-radius: 15px; padding: 10px; margin: 0 10px;  } 
.air-ambilances-bt{ display: flex; }    
.air-amvu-rht p{     font-size: 13px;
    letter-spacing: 0px;
    line-height: 17px;
}
.air-amvu-rht{     display: flex;
    flex-wrap: wrap;
    justify-content: end;
    padding-top: 18px;
    padding-left: 25px;
    padding-right: 10px;
}
.book-clr-btn{      display: flex;
    padding-left: 21px;
    position: relative;
    z-index: 99;
    transition: all .3s ease;
    padding-top: 11px;
    padding-bottom: 5px;

}
.book-clr-btn-padd{ padding-bottom: 50px;}
.air-amvu-rht a{ font-size: 12px; padding-bottom: 20px; color: #515050; }
.book-clr-btn a{ background: #000000;
    border-radius: 50px;
    padding: 1px 14px 1px 1px;
    display: flex;}
.book-clr-btn a>span{     background: #fff; transition:all 1s ease;
    border-radius: 50px;
    padding: 9px 24px 9px 23px;
    color: #000000;
}
.air-ambilances-mn.roadAmbulance{ margin-top:20px;  }
.air-ambilances-bt, .air-ambilances-mn{ transition:all 1s ease;  }
.air-ambilances-bt:hover .air-amvu-rht p, .air-ambilances-bt:hover .air-amvu-rht a{ color:#fff; }
.air-ambilances-bt:hover{ background: #f30000;  }
.book-clr-btn a img{     padding-left: 6px;
    width: 22px;
    height: 16px;
    margin-top: 12px;}
.air-ambilances-tp{ padding-top: 40px; }

.air-ambilances-mn{transition:all 1s ease;}
.air-ambilances-bt:hover  .book-clr-btn{ margin-left: 10px; }
.air-ambilances-bt:hover  .book-clr-btn a  { background:#fff; font-weight: 600;  }
.air-ambilances-bt:hover  .book-clr-btn a span  { background:#fff; color:#f30000; font-weight: 600; }
.air-ambilances-bt:hover  .book-clr-btn a img  { filter: brightness(50%) sepia(100) saturate(100) hue-rotate(25deg); }

@media only screen and (max-width: 1400px){
.book-clr-btn-padd{ padding-bottom: 52px; }
.our-tail-sol-bot .book-clr-btn a>span{      padding: 6px 24px 6px 23px;}
.book-clr-btn a img{   margin-top: 10px;}
}

@media only screen and (max-width: 767px){
	.redefined-ambulance .slick-dots li{ background:#abacad; border-radius: 50px; width: 12px; height: 12px;  }
	.redefined-ambulance .slick-dots li.slick-active{ background:#f30000;  width: 45px; height: 12px; }
	.redefined-ambulance .slick-dots{ bottom:-50px; left: 0px; text-align: center;  }
	.air-amvu-rht{ padding-top: 0px; }
	.air-ambilances-bt{ display:inherit;  }
    .our-tail-sol-bot .book-clr-btn a>span {
        padding: 6px 8px 6px 11px;
    }
    .our-tail-sol-bot:hover  .book-clr-btn { transition: all 1s ease; margin-left: 0px; }
    .our-tail-sol-bot .book-clr-btn{          justify-content: center !important;
        margin-left: 0px !important;
        margin-top: 19px;
        transform: translateX(-25px);}
    .book-clr-btn-padd{ padding-bottom: 0px; }
}


/*end redefining css start*/

/*about us css start*/
.gry-bg-clr{ background: #fafafa; border-radius: 50px;  }
.about-us-content h2{font-family: 'rubikbold'; color: #f30000; font-size:30px; text-transform: uppercase; padding-bottom: 15px; }
.about-us-content p{padding-bottom: 10px;
    font-size: 13px;
    color: #000; }
.about-us-content{     padding-top: 50px;
    padding-right: 30%; }
.book-clr-btn1{ padding-left: 0px; z-index: 3; position: relative; transition:all 1s ease; }
.book-clr-btn1 a{transition:all 1s ease; }
.book-clr-btn1:hover a{ margin-left: 10px;  }
.book-clr-btn1:hover a  { background:#fff;  }
.book-clr-btn1:hover a span  { background:#fff; color:#f30000; }
.book-clr-btn1:hover a img  { filter: brightness(50%) sepia(100) saturate(100) hue-rotate(25deg); }
.ltsrcphfa{ background: #fff;
    box-shadow: #e2e2e2 0 0 25px;
    border-radius: 12px;
    color: #f30000;
    font-size: 24px;
    font-family: 'rubikbold';
    text-align: center;
    padding: 40px 30px;
    transform: translatex(-228px);
    margin-top: 342px;
    z-index: 3;
    position: absolute;
     width: 50%;
    bottom: 76px;
}
.ltsrcphfa ul{ display: flex; flex-wrap: wrap; }
.ltsrcphfa ul li{ flex:0 0 20%; font-family: 'rubikbold'; font-size: 21px;  line-height: 18px; }
.ltsrcphfa span{ color: #000;
    font-size: 14px;
    font-family: 'rubikregular';
    line-height: 0px; }

    .desktop_view{ display: block;}
    .mobile_view{ display: none;}
.gry-bg-clr{ position: relative;}    
.abut-img{ position: relative;}
.abut-img::after{ content: "";
    position: absolute;
    right: -115px;
    bottom: 16px;
    /* redhealth-frontend/src/assets/images/home/about-crcle-img.webp */
    background:url('../images/home/about-crcle-img1.webp') right bottom no-repeat;
    width: 350px;
    height: 350px;
    z-index: 2;
    background-size: 100%;}



    @media only screen and (max-width: 1800px){
    .ltsrcphfa{     margin-top: 250px; width: 60%; } 
        
    }

    @media only screen and (max-width: 1680px){
        .ltsrcphfa{     margin-top: 190px; }
    
        }
    
        @media only screen and (max-width: 1440px){
        .ltsrcphfa {
            margin-top: 160px;
        } 
    }  

@media only screen and (max-width: 1400px){
.our-tail-sol-bot:hover .book-clr-btn {
    margin-left: 30px !important;}

    
.ltsrcphfa {
    margin-top: 140px;
}
.ltsrcphfa span{     font-size: 13px;}

}

@media only screen and (max-width: 1200px){
.abut-img::after{ background: inherit; }
.ltsrcphfa{ margin-top: 0px;;}
}
@media only screen and (max-width: 767px){
    .ltsrcphfa ul{ display: flex; flex-wrap: wrap; justify-content: center; }
    .desktop_view{ display: none;}
    .mobile_view{ display: block;}

.ltsrcphfa{ margin-top:86px;    transform: unset; background:none; box-shadow: none; padding:0px; margin-top:30px;  }
.gry-bg-clr{ background:none;  }
.about-us-content{ padding-top: 0px; padding-right: 0px; text-align: center; }
.about-us-content h2{ padding-bottom: 30px; color:#231f20;    font-family: 'rubikregular'; }
.about-us-content img{ padding-bottom: 30px; }
.book-clr-btn{ text-align: center;
    justify-content: center !important; }
header {
    padding-top: 10px;
}
.redefined-ambulance .slick-list{     padding: 0 13% 0 0 !important; }
.abut-img::after{ background: inherit;}
.zero-emi-circle_back_frst{ display: none;}
}

@media only screen and (max-width:768px){
    .ltsrcphfa{ position: relative;   bottom: 0px; width: inherit;}
}

@media only screen and (max-width: 480px){
.ltsrcphfa ul li{      flex: 0 0 33%;   margin-top: 20px; }
}
/*end about us css*/

/*products css start*/

p.product-sub-heading{     font-size: 18px;
    color: #222222;
    letter-spacing: 1px;
    text-transform: none;
    padding-top: 40px; }
.prodct-mn-tp{ padding-top: 50px; padding-bottom: 50px; }
.prodct-cnt-bot{ overflow: hidden;
    height: 400px;
    position: relative;
    z-index: 999;
    border-radius: 30px;
    padding: 50px 30px 20px;
    text-align: left;
    margin-bottom: 100px;
    border: #000 solid 1px;
    margin: 40px 24px 0px;

     
}


.prodct-cnt-bot:hover .circle_img_gry{     bottom:0px; height:100%;  background: #f00;  transform: inherit;
 width:100%; right: 0px; border-radius: inherit;   }
.prodct-cnt-bot-ent-event{ position: relative; z-index: 99; }
.pro-arrw-left{ position: relative; z-index: 99; }
.prodct-cnt-bot:hover .circle_img_gry img{     width: 60px;
    transform: inherit;
    position: absolute;  right: 17px; bottom: 24px; top: inherit; left: inherit; }
.prodct-cnt-bot:hover .prodct-cnt-bot-ent-event h2{ color:#fff; }
.prodct-cnt-bot:hover .prodct-cnt-bot-ent-event p{ color:#fff; }
.prodct-cnt-bot:hover .pro-arrw-left{ opacity: 1; filter: invert(100%); position: absolute; bottom: 30px; }
.prodct-cnt-bot:hover .circle_img_gry{ background:#f30000;  }
.prodct-cnt-bot-ent-event h2 {
    font-family: 'rubikmedium';
    text-transform: uppercase;
    color: #000000;
    font-size: 21px;
    letter-spacing: 0;
}
.prodct-cnt-bot-ent-event p {
       color: #000000;
    font-size: 14px;
    line-height: 21px;
    padding-bottom: 150px;
    padding-top: 0px;

}
.circle_img_gry {
    content: "";
    position: absolute;
    right: -93px;
    bottom: -121px;
    background: #e3e3e3;
    width: 276px;
    height: 290px;
    border-radius: 150px 150px 0 0;
    transform: rotate(-57deg);
    transition: all .5s ease;
    background: rgb(248, 113, 113);
    background: linear-gradient(152deg, rgba(248, 113, 113, 1) 35%, rgba(244, 186, 181, 1) 100%);
}
.pro-arrw-left{ opacity: 0;  padding-top:0px; }
.pro-arrw-left img {
    width: 40px;}
.circle_img_gry img{ width: 60px;
    transform: rotate(55deg);
    position: absolute;
    left: 124px;
    top: 53px;
}

@media only screen and (max-width: 1800px){
.prodct-cnt-bot:hover .pro-arrw-left {
          opacity: 1;
        /* margin-top: -60px; */
        filter: invert(100%);
        position: absolute;
        bottom: 25px;}
.prodct-cnt-bot:hover .prodct-cnt-bot{ height: 100%; }
}


@media only screen and (max-width: 1440px){
.prodct-cnt-bot{ height: 400px; }

}

@media only screen and (max-width: 767px){
/* .prodct-cnt-bot{ height: auto; margin-bottom: 40px; } */
.prodct-cnt-bot { margin:0px 24px 0px; height: 280px; margin-bottom: 40px;     padding: 20px 30px 20px; margin-left: 5px; margin-right: 5px; }
.prodct-mn-tp{ padding-top: 0px; padding-bottom: 5px; }
.prodct-cnt-bot-ent-event p{ padding-bottom: 10px; }
.pro-arrw-left{ display:block; }
.product_slider .slick-dots li{ background:#abacad; border-radius: 50px; width: 12px; height: 12px;  }
.product_slider .slick-dots li.slick-active{ background:#f30000;  width: 45px; height: 12px; }
.product_slider .slick-dots{ bottom:-4px; left: 0px; text-align: center;  }
.product_slider button:before{ content:inherit !important;  }
.circle_img_gry img { width: 50px;}
.circle_img_gry{  right: -81px; bottom: -167px;  width: 220px;  height: 276px; }
.prodct-cnt-bot:hover .circle_img_gry img{ width: 50px;}
.prodct-cnt-bot-ent-event h2{ font-size: 18px;}
}

/*end products css*/

/*our tailored solutions css start*/

p.tailored-solution-sub-heading{     font-size: 18px;
    color: #222222;
    letter-spacing: 1px;
    text-transform: none;
    padding-top: 40px; }
.our-tail-sol-mn{ padding-top: 40px; }
.our-tail-sol-bot{ margin: 0px 24px; overflow:hidden; background: rgb(249,249,249);
background: linear-gradient(312deg, rgba(249,249,249,1) 0%, rgba(226,227,227,1) 100%); height: 100%; position: relative; z-index:999;
    border-radius: 30px;
    padding: 50px 50px;
    text-align: center;}
.circle_img{     content: "";
    position: absolute;
    right: -31px;
    bottom: -136px;
    /* background: #e3e3e3; */
    width: 300px;
    height: 300px;
    border-radius: 150px 150px 0 0;
    
    transform: rotate(-26deg);
    transition: all .5s ease;
}
.hosp-ent-event{ padding-bottom: 120px;
    position: relative;
    z-index: 99; 
    height: 386px;
}
.hosp-ent-event h2{     font-family: 'rubikmedium';    color: #000000;
    font-size: 21px;
    line-height: 21px;
    padding-bottom: 20px;
    padding-top: 20px;
    z-index: 11;
    position: relative;
 }
 .circle_img img{     position: absolute;
  
    bottom: -20px;
    transition: all .5s ease;
    right: -43px;
    width: 400px;
    height: 375px;
    border-radius: 300px 300px 0 0;
    transform: rotate(25deg);
}
.circle_back_frst{     content: "";
    position: absolute;
       left: 111px;
    top: 271px;
    background: #e7e7e7;
    width: 400px;
    height: 400px;
    border-radius: 200px;
    transform: rotate(-45deg);
    transition: all 3s ease;
     z-index: 2;
}
.circle_back_scnd{     content: "";
    position: absolute;
        left: 0px;
    top: 60px;
    background: #e3e3e3;
    width: 370px;
    height: 370px;
    border-radius: 200px;
    transform: rotate(-45deg);
    transition: all .5s ease;
    z-index: 2;}



.our-tail-sol-bot:hover .circle_img img{ display: block; bottom:0px; 
	z-index: 2; }
.our-tail-sol-bot:hover  .circle_img {background: none;}
.our-tail-sol-bot:hover  .book-clr-btn { transition: all 1s ease; margin-left: 15px; }
.our-tail-sol-bot:hover  .book-clr-btn a  { background:#fff;  }
.our-tail-sol-bot:hover  .book-clr-btn a span  { background:#fff; color:#f30000; }
.our-tail-sol-bot:hover  .book-clr-btn a img  { filter: brightness(50%) sepia(100) saturate(100) hue-rotate(25deg); }



@keyframes fadein {
 from  {opacity: 1;}
 to  {opacity: 0;}
}

.our-tail-sol-bot:hover  .circle_back_frst{
  animation: fadein .25s alternate ease-in-out  ; top:196px;

}


.our-tail-sol-bot{
    position: relative;
    overflow: hidden; 
min-height: 580px;
}
  
    .our-tail-sol-bot .circle_back_frst{  
    bottom: 0;
    content: "";
     opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
    position: absolute;
    left:inherit;
    right: -33px; 
     transition-duration: 1.3s; }
  
    .our-tail-sol-bot:hover .circle_back_frst{ top: 0px; height: 100%;
    opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    -webkit-opacity: 0;
    transition-duration: 1.3s;
    width: 100%; }

    @media only screen and (max-width:1680px){   
    /* .circle_img img{ width: 350px; height: 350px; } */
    .circle_back_frst{  left: 55px; top: 353px;}
    .our-tail-sol-bot{ height: 70vh;}
    .circle_img img{ right: -3px;
        width: 358px;
        height: 262px; }
        .circle_img{ bottom: -47px;}
    }

    @media only screen and (max-width:1500px){   
    .our-tail-sol-bot .circle_back_frst{
        right:-58px;
      }}
    @media only screen and (max-width:1400px){  
    .circle_back_frst {
        left: 27px;
    }
       .blog-lft-mn h2{
        font-size: 16px;
    }
    .blog-lft-mn p{
        font-size: 14px;
    }
    p.expand-our-reach{
        padding-top: 20px;
    }
    p.product-sub-heading{
        padding-top: 20px;
    }
    p.tailored-solution-sub-heading{
        padding-top: 20px;
    }
    .zero-int-cont h2{
        font-size: 18px;
        line-height: 24px;
    }
    }


    @media only screen and (max-width: 767px){
        p.product-sub-heading {
            padding-top: 30px;
            padding-bottom: 30px;
        }
        p.tailored-solution-sub-heading {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    .circle_back_frst{ background: inherit;}
    .our-tail-sol-bot{ margin:0px;}
    .our-tail-sol-bot{     padding: 50px 30px 50px;}
    .our-tail-sol-mn {
        padding-top: 0px;
    }
    .bg-white {
        padding-top: 50px;
    }
}

@media only screen and (max-width: 767px){
    .circle_back_frst{ display:none;} 
    .hosp-ent-event p{     padding-bottom: 10px; }
.our-tail-sol-bot{ margin-bottom: 50px; }
.our-tail-sol-bot{ height: auto; }
.tailored-solutn .slick-dots li{ background:#abacad; border-radius: 50px; width: 12px; height: 12px;  }
.tailored-solutn .slick-dots li.slick-active{ background:#f30000;  width: 45px; height: 12px; }
.tailored-solutn .slick-dots{ bottom:0px; left: 0px; text-align: center;  }
.tailored-solutn .slick-list{ padding: 0 13% 0 0 !important; }
.tailored-solutn button:before{ content:inherit !important;  }
} 
/*end our tailored solutions css*/

/*our esteemed partners css start*/
.esteemed-partner-tp{ width: 100%; float: left; }
.tab-nav-pd-bth{ display:flex; justify-content:center; padding-top:30px; }
.tabs-nav-mn-tp li a{ padding: 14px 50px 11px;
    font-family: 'rubikmedium';
    font-size: 18px;
    color: #000;
    display: block;
    text-align: center;	
    text-decoration: none;
    cursor: pointer;
    letter-spacing: 3px; 

    }
.tab-env-navi-active a{ border-bottom-color: transparent; 
    color: #fff !important; cursor: default;}
.tabs-stage-cnt{ border-radius: 0 0 6px 6px; border-top: 0; clear: both;
 padding: 70px 0px 0px; position: relative; top: -1px;}
.tab-nav-compliance-content ul{ display:flex; flex-wrap:wrap; }
.tab-nav-compliance-content ul li{ flex:0 0 20%; max-width:20%; }
.tab-nav-compliance-content ul li a{ color: #000000;}
.tabs-env-main{ padding-top:30px;}
.tab-nav-compliance-content ul li a span {  display: inline; font-size: 24px;padding-left: 10px;}
.tabs-env-main ul {
    margin: 0px;
    padding: 15px 50px;
    list-style: none !important;
    display: flex;
    justify-content: center;
}
.tabs-nav-mn-tp {  border: #019046 solid 1px;  
    display: table;  width: 100%; border-radius: 5px;
    border:unset;
    display: table;
    width: 100%;
    border-radius: 15px;
}
.tab-env-navi-active{  font-family: 'rubikmedium';   background: #f30000;
    color: #fff;
    border-radius: 50px;
    text-transform: uppercase; border:#f30000 solid 1px !important; }
.tab-env-navi-ent{ border:#676767 solid 1px; border-radius: 50px; }    
p.expand-our-reach{     font-size: 18px;
    color: #222222;
    letter-spacing: 1px;
    text-transform: none;
    padding-top: 40px; }
.tabs-env-main ul li {
    margin: 0px 1%;     border: #676767 solid 1px;
    border-radius: 50px;
}
.esteemed-partners-lst .slick-slide {
    margin: 0 25px; opacity: 1;
}  
.our-est-prtnr{      border: #e3e3e3 solid 1px;
    text-align: center;
   
    border-radius: 15px 1px 15px 1px;
    padding: 15px;
}
.our-est-prtnr a img{ display: inline-block;}
.esteemed-partners-lst{ padding-top: 50px; padding-bottom: 65px;  }
/*.esteemed-partners-lst .slick-list {
    margin: 0 -20px;
}*/
.esteemed-partners-lst .slick-list {
    padding: 0 13% 0 0 !important;
}
/*#tab-nav-2 .esteemed-partners-lst{ padding-bottom: 100px;}*/
.esteemed-partners-lst  .slick-list{ height:107px}


@media only screen and (max-width: 1400px){
.our-est-prtnr{  
    padding: 5px;
}

}

@media only screen and (max-width: 767px){
    p.expand-our-reach{     padding-top: 30px;}
    .esteemed-partners-lst{ padding-bottom: 0px;  }
.our-est-prtnr a img{ width: 100px; }
#tab-nav-2 .esteemed-partners-lst{ padding-bottom: 0px;}
.esteemed-partners-lst .slick-slide {
    margin: 0 5px; opacity: 1;}  
.blog-lft-mn{ margin-bottom:0px;}

}

@media only screen and (max-width: 480px){
	.tabs-nav-mn-tp li a{  padding: 7px 30px 6px; font-size: 14px; }
}

/*end our esteemed partners css*/

/*blogs css start*/
.desktopShow{ display: block;}
.mobileShow{ display: none;}
.blog-bt-mn{ padding-top: 70px; }
.blog-lft-mn{ background: rgb(245,245,245);
background: linear-gradient(90deg, rgba(245,245,245,1) 57%, rgba(254,254,254,1) 100%);
 border-radius: 20px; padding:20px;  }
.blg-arrow-bt{ margin-top:100px;  }
.blog-lft-mn h2{ font-family: 'rubikmedium'; font-size: 21px; color:#231f20; padding-top: 20px; padding-bottom: 5px; }
.blog-lft-mn p{ font-size: 16px; color:#231f20; }
.blog-rght-tp-mn{ background: linear-gradient(90deg, rgba(245, 245, 245, 1) 57%, rgba(254, 254, 254, 1) 100%);
    border-radius: 20px; margin-bottom: 30px;
    padding: 20px; }
.blogs_slide .slick-next:before, .blogs_slide .slick-prev:before { content:"" !important; }  
.blogs_slide button:before{ content:inherit !important;  }
.blogs_slide .slick-dots li{ background:#f97f7f; border-radius: 50px; width: 12px; height: 12px;  }
.blogs_slide .slick-dots li.slick-active{ background:#f30000;  width: 45px; height: 12px; }
.blogs_slide .slick-dots{ bottom:-80px; right: 0px; text-align: center;  }


.blogs_slide_mb .slick-next:before, .blogs_slide_mb .slick-prev:before { content:"" !important; }  
.blogs_slide_mb button:before{ content:inherit !important;  }
.blogs_slide_mb .slick-dots li{ background:#f97f7f; border-radius: 50px; width: 12px; height: 12px;  }
.blogs_slide_mb .slick-dots li.slick-active{ background:#f30000;  width: 45px; height: 12px; }
.blogs_slide_mb .slick-dots{ bottom:-80px; right: 0px; text-align: center;  }





@media only screen and (max-width: 767px){
    .blog-rght-tp-mn{  margin-top: 30px !important;}
    .desktopShow{ display: none;}
    .mobileShow{ display: block;}
    .blog-lft-mn h2{ font-size: 14px;}
    .blog-lft-mn h2{ padding-top: 10px !important;}
    .blog-lft-mn p {
        font-size: 12px;}
    .blg-arrow-bt a img{ width: 20px;}    
    .blog-rght-tp-mn{ padding: 20px 20px 0px;}

}
/*end blogs css*/

/*speak with us css start*/
.speak-with-us-cont{     background: #f7f7f7;
    box-shadow: #f30000 4px 10px 0px;
    padding:100px;
    border-radius: 50px;
 }
 .speak-with-us{  margin-bottom: 120px; margin-top: 60px; }
 .speak-with-us-lft-top{ padding-right: 30%; }
.speak-with-us-lft-top h2{ color: #000000;
    font-size: 40px;
    letter-spacing: 5px;  }
.speak-with-us-lft-bot{ padding-top: 50px; }
.speak-with-us-lft-bot ul li a{     font-family: 'rubikbold';
    color: #000;
    font-size: 18px;
    line-height: 45px;   }
.label-name-text{  font-family: 'rubikbold'; display: block; color:#000000;  font-size:18px; }   
.speak-name-field {
    width: 100%; background: unset;
    border-bottom: #010101 solid 1px;
    font-size: 16px; line-height: 40px;
    height: 50px;
    margin-bottom: 20px;
    border-top: none;
    border-right: none;
    border-left: none;
    color: #aaaaaa;
} 
.speak-with-us-rght .error-msg{     margin-top: -20px;  margin-bottom: 18px !important; }
.speak-name-field:focus{ border-bottom: #010101 solid 1px; background: unset; outline: unset; }
.form-mssage-inp{ padding-top: 20px; }
.social-icon-footer{ padding-top: 100px; }
.social-icon-footer ul{ display: flex; justify-content: end; }
.social-icon-footer ul li{ padding-left: 30px; }

@media only screen and (max-width: 1680px){
	.speak-with-us-lft-top h2{ font-size: 30px; }
	.speak-with-us-lft-bot ul li a{ font-size: 14px;
    line-height: 38px; }
    .label-name-text{     font-size: 14px;	 }
    .speak-name-field{ font-size: 14px; }
}

@media only screen and (max-width: 767px){
.speak-with-us-lft-bot ul li a{ color:#df3021 !important;  }

.speak-with-us-cont {
    background:none;
    box-shadow: none;
    padding: 0px;
    border-radius: 0px;
}
.speak-with-us-rght{ background: #f7f7f7;
    box-shadow: #f30000 4px 10px 0px;
    padding:30px 30px;
    border-radius: 50px; }
.social-icon-footer{ padding-top: 3px; display: none;
    transform: translateY(-50px); }   
    .social-icon-footer ul li a img{ width:20px; }    
    .social-icon-footer ul li {
        padding-left: 0px; padding-right: 20px;
    }
    .speak-with-us{ margin-top:50px; margin-bottom: 50px;}
    .blogs_slide_mb .slick-dots{ bottom: -40px;}
    .footer-mn{ padding-top: 50px; padding-bottom: 50px;}
}


@media only screen and (max-width: 414px){
	.speak-with-us-cont{     padding: 0px 20px; }
}
/*end speak with us css*/


/*footer css start*/
.footer-mn{ width: 100%; float: left; padding-top: 70px; padding-bottom:20px;
    background: rgb(0,0,0);
   background: linear-gradient(90deg, rgba(0,0,0,1) 57%, rgba(56,0,0,1) 100%); }
   .address-cnt h2{     color: #fff;  font-size: 18px; padding-bottom: 30px;  }
   .address-cnt p{ color: #fff;
       font-size: 18px; padding-bottom: 20px; }
   .address-cnt ul li a{ font-size:18px; color:#fff; }
   .address-cnt ul.call-email li{ line-height: 45px;}
   .address-cnt ul.social-icon-ft{ display: flex; justify-content: start; padding-top:30px;}
   .address-cnt ul.social-icon-ft li{ padding-right: 25px; }
   .address-cnt-pdd{ padding:0 35px;  }
   /* .mobileSect_Show{ display: none; } */
   .deskSect_Show{ display:block; }
   .speak-with-us-lft-bot ul li a{ text-align: center; color:#000;  }
   .copy-right-txt{ padding-top: 80px; padding-bottom:0px;  }
   .copy-right-txt p{ color:#7e8080; font-size: 18px;  }
   .copy-right-txt p a{ color:#7e8080; font-size: 18px;  }
   
   
   @media only screen and (max-width: 1680px){
       .address-cnt h2{     font-size: 16px; }
       .address-cnt p{ font-size: 14px; }
       .address-cnt ul li a{ font-size: 14px; }
   }
   
   @media only screen and (max-width: 767px){
   .address-cnt-pdd{ text-align: center; }
   .address-cnt ul.social-icon-ft{ justify-content: center; }
   .address-cnt{ text-align: center; }
       .address-cnt-pdd{ border-bottom: #fff solid 1px;
       padding-bottom: 50px;}
        .address-cnt{ text-align: center; padding-top: 50px; }
        .address-cnt h2{ color: #f00;display: none;}
   .speak-with-us-lft-bot {
       padding-top:0px;
       text-align: center;
       padding-bottom: 50px;
   }
   .speak-with-us-lft-top{     text-align: center;
       padding: 0px 10%;}
   .address-cnt ul.call-email li {
       line-height: 35px;
   }
   .copy-right-txt p{ font-size: 11px; }
   .copy-right-txt p a{ font-size: 11px; }
   .speak-with-us-lft-top p{ padding-top: 30px; padding-bottom: 30px;}
   }
   /*end footer css*/

   /*testimonial css start*/
   #product__slider .product__slider-thmb .slick-slide.slick-active.slick-current{ border:#f00 solid 3px !important; }
.bg-grey-testim{ width: 100%; float: left; background:#f6f6f6; padding-top: 60px; padding-bottom: 0px; }
.testim-rvw-right-tp:before{ content: ""; position: absolute; left: 0px; top: 0px; background: url("../images/home/coma-icon.webp") left top no-repeat;
width: 100px; height: 100px; }
.testim-rvw-right-tp:after{ content: "";
    position: absolute;
    right: 0px;
    bottom: 30px;
    background: url("../images/home/coma-icon.webp") left top no-repeat;
    width: 100px;
    height: 100px;
}
.testim-rvw-right-bt h2{     font-family: 'rubikmedium'; color:#df3021; font-size: 18px; margin:0px; }
.testim-rvw-right-bt h3{ color:#000	; font-size: 16px;}
.testim-rvw-right-tp{ padding-bottom: 100px;
    padding-left: 41px;    padding-bottom: 70px;
    padding-left: 41px;
    padding-right: 0px;
    padding-top: 60px;
}
.testim-rvw-right-bt{ padding-left: 41px; }
#product__slider .product__slider-thmb{ position: relative; }
#product__slider .product__slider-thmb .slick-slide{ position: relative; background: #efefef;
    border-radius: 50px; z-index: 99;
    border: #b4b4b4 solid 1px !important;
    opacity: 1 !important;
    cursor: pointer;
    margin-top: 50px;
 }

#product__slider .product__slider-thmb .slick-list{ height: 450px; }
#product__slider .product__slider-thmb .slick-slide:nth-child(1){  position: absolute;
    left: 50px;
    top: 0px;
    width: 70px !important;
    height: 70px;
    background: #efefef;
    /* border: #aeaeae solid 1px !important; */
}
#product__slider .product__slider-thmb .slick-slide:nth-child(2){     position: absolute;
    left: 100px;
    top: 117px;
    width: 55px !important;
    height: 55px;
    background: #efefef;
    /* border: #aeaeae solid 1px !important; */

}
#product__slider .product__slider-thmb .slick-slide:nth-child(3){     position: absolute;
    left: 250px;
    top: 89px;
    width: 60px !important;
    height: 60px;
    background: #efefef;
    /* border: #aeaeae solid 1px !important; */
}
#product__slider .product__slider-thmb .slick-slide:nth-child(4){  position: absolute;
    left: 305px;
    top: 181px;
    width: 50px !important;
    height: 50px;
    background: #efefef;
    /* border: #aeaeae solid 1px !important; */
}

#product__slider .product__slider-thmb .slick-slide:nth-child(5){position: absolute;
    left: 175px;
    top: 182px;
    width: 90px !important;
    height: 90px;
    background: #efefef;
    /* border: #aeaeae solid 1px !important; */
}

#product__slider .product__slider-thmb .slick-slide:nth-child(6){     position: absolute;
    left: 305px;
    top: 270px;
    width: 80px !important;
    height: 80px;
    background: #efefef;
    /* border: #aeaeae solid 1px !important; */
}

#product__slider .product__slider-thmb .slick-slide:nth-child(7){     position: absolute;
    left: 178px;
    top: 310px;
    width: 75px !important;
    height: 75px;
    background: #efefef;
    /* border: #aeaeae solid 1px !important; */
}
#product__slider .product__slider-thmb .slick-slide:nth-child(8){      position: absolute;
    left: 71px;
    top: 251px;
    width: 65px !important;
    height: 65px;
    background: #efefef;
    border: #aeaeae solid 1px !important;}

.testim-str{ display: flex; justify-content: end; }
.testim-str ul{ display: flex; justify-content: end; }
.testim-str ul li{ display: inline-block; }
.testim-rvw-icn-mn{      position: relative;
    padding: 0px 50px;
    background: url("../images/home/line-icn.webp") left top no-repeat;
    height: 300px;
    margin-top: 0px;

}
.testimonial-tabs{
         margin: 0px;
    padding: 0px;
    list-style: none;
    display: flex;
    width: 100%;
    float: left;
    justify-content: center;
    padding-top: 60px;
}
.testimonial-tabs li{ 
       border: #676767 solid 1px;
    border-radius: 50px;
    background: none;
    color: #222;
    display: inline-block;
    padding: 15px 15px;
    cursor: pointer;
    width: 20%;
    text-align: center;
    margin: 0 2%;
    letter-spacing: 2px;
}

.testimonial-tabs li.current{
	 
    font-family: 'rubikmedium';
    background: #f30000;
    color: #fff;
    border-radius: 50px;
    text-transform: uppercase;
    border: #f30000 solid 1px !important;
    margin: 0 1%;
}

.tab-content{
	display: none;
		padding: 15px;
}

.tab-content.current{
	display: inherit; 
}
.testim-rvw-icn-mn{ margin-top: 100px; }
.testim-rvw-photo{ display: none; }
.starIcon:hover {
  cursor: pointer;
}

#theStars {
  margin: 0 auto;
}

.ratingControl {
  position: relative;
  display: inline-flex;
  direction: rtl;
}
.ratingControl__radio {
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
}
.ratingControl__star {
      position: relative;
    display: block;
    height: 30px;
    width: 15px;
    cursor: pointer;
    overflow: hidden;
}
.ratingControl__star:nth-last-of-type(odd)::before, .ratingControl__star:nth-last-of-type(odd)::after {
  left: 0;
  -webkit-clip-path: polygon(50% 0%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
          clip-path: polygon(50% 0%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
}
.ratingControl__star:nth-last-of-type(even)::before, .ratingControl__star:nth-last-of-type(even)::after {
  right: 0;
  -webkit-clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%);
          clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%);
}
.ratingControl__star::before, .ratingControl__star::after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
}
.ratingControl__star::before {
  width: 205%;
  background-color: #000;
}
.ratingControl__star::after {
  background-color: #f2b600;
}
/*.ratingControl__star:hover::after, .ratingControl__star:hover ~ .ratingControl__star::after, .ratingControl__radio:checked ~ .ratingControl__star::after {
  width: 200%;
}

.ratingControl__star.active::after, .ratingControl__star.active ~ .ratingControl__star::after, .ratingControl__radio:checked ~ .ratingControl__star::after {
  width: 200%;
}*/

.ratingControl__star.active::after { width: 200%;
}

.ratingControl__star.active::before {background-color:#f2b600;}



@media only screen and (max-width: 1680px){
	.testim-rvw-right-bt h2{ font-size: 14px; }
	.testim-rvw-right-bt h3{ font-size: 14px; }
	.testim-rvw-right-tp p{ font-size: 14px; }
}

@media only screen and (max-width:1024px){
.testimonial-tabs li{ width: auto; }
}

@media only screen and (max-width: 767px){
.ratingControl{     background: white;
    border-radius: 5px;
    margin-right: 10px;
    padding: 0px 5px; }
.testimonial-tabs li{ width: 98% !important; }
#product__slider{ display: none; }
.testim-rvw-icn-mn{ background: none; }
.testim-rvw-icn-mn{ position: relative; }
.testim-rvw-photo{     border: #4e4e4e solid 1px;
    background: #fff;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    position: absolute;
    left: 38%; top: 0px;  }
.testim-rvw-right-tp:before{ width: 60px;
    height: 60px;
    background-size: 45%;
    opacity: 0.2;
    left: 30px;
    top: 100px;}
.testim-rvw-right-tp:after{ width: 60px; height: 60px; background-size: 45%; opacity: 0.2; }
.testim-rvw-right-tp p{ color:#fff;  }
.testim-rvw-right-bt h2, .testim-rvw-right-bt h3{ color:#fff;  }
.testim-rvw-icn-mn{ margin-top: 10px; }
.testim-rvw-right-tp{    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 71px;}
.testim-rvw-photo{ display: block; }
.product__slider-main .slick-slide{ padding-top: 49px; }
.product__slider-main .testimonial-tabs{ background: #ce2c1e; }
.testim-rvw-icn{ background: #ce2c1e;
    border-radius: 40px;
    height: 300px; margin: 0 10px; }
.testim-rvw-right-tp:after{ bottom: inherit; }    
.testim-rvw-right-bt {
    padding-left: 20px;
}
.testim-rvw-icn-mn{ padding:0px; }
.product__slider-main .slick-dots li{ background:#abacad; border-radius: 50px; width: 12px; height: 12px;  }
.product__slider-main .slick-dots li.slick-active{ background:#f30000;  width: 45px; height: 12px; }
.product__slider-main .slick-dots{     bottom: -60px;
    left: 0px;
    text-align: center; }
.bg-grey-testim{ padding-top: 50px;
    padding-bottom: 150px; }    
    .product__slider-main button:before{ content:inherit !important;  }
    .testimonial-tabs{ padding-top: 30px;}
    .tab-content{ padding-top: 30px;}
}

@media only screen and (max-width: 480px){
.testimonial-tabs li{     padding: 9px 15px; font-size: 12px;     height: 47px;
    line-height: 31px; }
.testim-rvw-photo{ width: 80px; height: 80px;}
.ratingControl__star{     height: 20px;
    width: 10px;
}
.testim-rvw-right-bt h2, .testim-rvw-right-bt h3 {
    font-size: 12px;
}
 
}
@media only screen and (max-width: 393px){
.testim-rvw-photo{ left: 36%; }
.testimonial-tabs li {
    padding: 9px 5px;}
.testimonial-tabs li{ height: 42px;
    line-height: 26px; }    
}
/*end testimonial css*/

/*zero interest EMI css start*/
.zero-int-sol-bot{  margin: 0px 24px;
    overflow: hidden;
    background: rgb(249, 249, 249);
    background: linear-gradient(312deg, rgba(249, 249, 249, 1) 0%, rgba(226, 227, 227, 1) 100%);
    height: 100%;
    position: relative;
    z-index: 999;
    border-radius: 30px;
    padding: 30px 30px;
    text-align: left; transition: all 1s ease; }
.zero-int-cont{ padding-bottom: 120px; }
.zero-int-cont h2{ font-family: 'rubikbold';
    text-transform: uppercase;
    color: #000000;
    font-size: 26px;
    letter-spacing: 0;
    line-height: 30px; }
.zero-int-cont p{     color: #000000;
    font-size: 14px;
    line-height: 21px;
    padding-bottom: 120px;
    padding-top: 20px;
 }

.zero-emi-circle_img{ content: "";
    position: absolute;
    left: 31%;
    bottom: -28px;
    width: 250px;
    height: 250px;
    border-radius: 150px 150px 0 0;
    transform: rotate(3deg);
    transition: all .5s ease;
}

.zero-emi-circle_back_frst{  content: "";
    position: absolute;
    left: 100px;
    top: 148px;
    background: rgb(202,202,202);
background: linear-gradient(90deg, rgba(202,202,202,1) 0%, rgba(226,226,226,1) 16%);
    width: 420px;
    height: 420px;
    border-radius: 200px;
    transform: rotate(-45deg);
    transition: all 3s ease;

}

.zero-emi-circle_back_scnd{     content: "";
    position: absolute;
        left: 0px;
    top: 60px;
 background: rgb(202,202,202);
background: linear-gradient(90deg, rgba(202,202,202,1) 0%, rgba(209,209,209,1) 16%);
    width: 370px;
    height: 370px;
    border-radius: 200px;
    transform: rotate(-45deg);
    transition: all .5s ease;
   }
.zero-int-cont h2 span{ color:#df3021;  }
/*.zero-book-clr-btn a{ background: #fff; }*/
.zero-book-clr-btn a { z-index: 99; position: relative;
    background: #fff;
    border-radius: 50px;
    padding: 9px 24px 9px 23px;
    color: #000000; border:#222222 solid 1px; color:#222222;}
.zero-int-sol-bot:hover .zero-book-clr-btn a{ color:#df3021; border:#fff solid 1px; }
.zero-int-sol-bot:hover .zero-book-clr-btn{ transition: all 1s ease; margin-left: 15px; }
.ZeroInterestEmi .slick-dots li{ background:#abacad; border-radius: 50px; width: 12px; height: 12px;  }
.ZeroInterestEmi .slick-dots li.slick-active{ background:#f30000;  width: 45px; height: 12px; }

@media only screen and (max-width: 767px){
.zero-int-sol-bot{ margin-bottom: 50px; }
.zero-int-sol-bot{ height: auto; }

.zero-interest-Emi .slick-dots li{ background:#abacad; border-radius: 50px; width: 12px; height: 12px;  }
.zero-interest-Emi .slick-dots li.slick-active{ background:#f30000;  width: 45px; height: 12px; }
.zero-interest-Emi button:before{ content:inherit !important;  }

.zero-interest-Emi .slick-dots{ bottom:0px; left: 0px; text-align: center;  }
.zero-interest-Emi .slick-list{ padding:0 !important; }
.zero-int-cont h2{ font-size: 18px; line-height: 22px; }
.zero-interest-Emi .slick-slide{ margin-right:0px; opacity: 1; }  
}    
/*end zero interest EMI css*/


/*search with I have mb start*/
@media only screen and (max-width: 767px) {
    .mb-search{     background: #f00;
        margin-top: 64px;
        padding-bottom: 50px; }
    .srch_bx{ background:#fff; }
    .symptom-speak-txt ul li a.active{     background: #231f20;
        border-radius: 20px;
        border: unset; }
    .symptom-speak-txt ul li{    
        background: #fff;
        border-radius: 20px;
        border: unset;
    }
    
    .container_custom .banner-left-cnt{     margin-top: 50px; text-align: center;
        margin-bottom: 50px;}
     
.banner-left-cnt h2 {   font-size: 21px;}
.banner-left-cnt h3 {  font-size: 30px;}
.banner-left-cnt .error-msg{ text-align: left;  margin-top: -17px;}
/* .input-name-field{ margin-bottom: 10px; } */
    }
    /*end search with I have mb*/

    .error-message {
        color: red;
        font-size: small;
      }
     